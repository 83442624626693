<template>
  <div>
    <div>
      {{ $t('omw-marketing-total-campaign-clicks') }}:
      {{ clickData.totalClicks }}
    </div>
    <div>{{ $t('omw-stats-earliest') }}: {{ earliestDate }}</div>
    <div>{{ $t('omw-stats-latest') }}: {{ latestDate }}</div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    clickData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    earliestDate() {
      if (!this?.clickData?.earliestDate) return null;
      return this.$d(new Date(this?.clickData?.earliestDate), 'long');
    },
    latestDate() {
      if (!this?.clickData?.latestDate) return null;
      return this.$d(new Date(this?.clickData?.latestDate), 'long');
    },
  },
});
</script>

<style></style>
