<template>
  <div class="container pt-2">
    <b-loading :active.sync="isLoading"></b-loading>
    <p v-if="error" class="has-text-danger">{{ error }}</p>
    <b-field style="width: 25rem">
      <b-datepicker
        v-model="selectedDateRange"
        :placeholder="$t('omw-feedback-date-range')"
        :range="true"
        :max-date="maxDate"
      >
      </b-datepicker>
      <div class="buttons">
        <b-button type="is-link" @click="clearDates()">{{
          $t('general-clear')
        }}</b-button>
        <b-button type="is-link" @click="fetchData()">{{
          $t('omw-feedback-refresh-data-label')
        }}</b-button>
      </div>
    </b-field>
    <div class="columns">
      <div class="column">
        <monthly-clicked-activities v-if="stats" :data="stats.clicksPerMonth" />
      </div>
      <div class="column">
        <clicks-over-time v-if="stats" :data="stats.clicksPerDay" />
      </div>
    </div>
    <click-data-table v-if="stats" :click-data="stats" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import Papa from 'papaparse';
import saveFile from 'save-as-file';

import { getStats } from '@/services/stats';
import MonthlyClickedActivities from './MonthlyClicksOverTime';
import ClicksOverTime from './ClicksOverTime';
import ClickDataTable from './ClickDataTable';

export default defineComponent({
  name: 'OmwStats',
  components: {
    MonthlyClickedActivities,
    ClicksOverTime,
    ClickDataTable,
  },
  data() {
    return {
      maxDate: new Date(),
      selectedDateRange: undefined,
      stats: undefined,
      error: undefined,
      isLoading: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true;
        if (this?.selectedDateRange?.[0] && this?.selectedDateRange?.[1]) {
          this.stats = await getStats(
            this?.selectedDateRange[0],
            this?.selectedDateRange[1],
          );
        } else {
          this.stats = await getStats();
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isLoading = false;
      }
    },
    clearDates() {
      this.selectedDateRange = null;
    },
  },
});
</script>
