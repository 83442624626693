<template>
  <apexchart
    width="500"
    type="line"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import Vue from 'vue';
import Apexchart from 'vue-apexcharts';

export default defineComponent({
  name: 'ClicksOverTime',
  components: {
    Apexchart,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {},
    };
  },
  computed: {},
  watch: {
    data: {
      immediate: true,
      handler(newVal) {
        if (!newVal) return;
        const seriesData = newVal.map((click) => {
          return click.totalClicks;
        });
        const categories = newVal.map((click) => {
          return click.day;
        });

        this.options = {
          chart: {
            id: `${this.$t('omw-stats-hit-count-by-day')}`,
          },
          xaxis: {
            categories,
          },
          stroke: {
            curve: 'smooth',
          },
          title: {
            text: `${this.$t('omw-stats-hit-count-by-day')}`,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: '16px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: '#263238',
            },
          },
        };
        this.series = [
          {
            name: `${this.$t('omw-marketing-number-clicks')}`,
            data: seriesData,
            type: 'area',
          },
        ];
      },
    },
  },
  created() {
    // const chartEl = this.$refs.chart;
    // this.chart = new ApexCharts(chartEl, this.options);
    // this.chart.render();
  },
});
</script>

<style></style>
