import axios from 'axios';
import configData from '@/config/config.json';
import { API } from 'aws-amplify';

export const getStats = async (dateFrom = null, dateTo = null) => {
  let dateFromString;
  let dateToString;
  const params = new URLSearchParams();
  if (dateFrom) {
    dateFromString = Vue.$moment(dateFrom).format('YYYY-MM-DD');
  }
  if (dateTo) {
    dateToString = Vue.$moment(dateTo).format('YYYY-MM-DD');
  }
  let url = `/omw-clicks/stats`;
  if (dateFromString) {
    params.append('dateFrom', dateFromString);
  }
  if (dateToString) {
    params.append('dateTo', dateToString);
  }
  url += `?${params.toString()}`;
  const data = await API.get('omw', url);
  return data;
};

export const getFailedMessages = (dateFrom = null, dateTo = null) => {
  throw new Error('not implemented');
  // const params = new URLSearchParams();
  // if (dateFrom && dateTo) {
  //   params.append('dateFrom', dateFrom);
  //   params.append('dateTo', dateTo);
  // }
  // const options = {
  //   method: 'GET',
  //   params: params,
  //   url: configData.msgStats.failedMessagesUrl,
  // };
  // return apiClient(options);
};

export const getDeliveredHitCount = () => {
  throw new Error('not implemented');
  // const options = {
  //   method: 'GET',
  //   url: configData.msgStats.deliveredHitCount,
  // };
  // return apiClient(options);
};

export const getHitCount = () => {
  throw new Error('not implemented');
  // const options = {
  //   method: 'GET',
  //   url: configData.msgStats.hitCount,
  // };
  // return apiClient(options);
};

export const getHitCountByDay = () => {
  throw new Error('not implemented');
  // const options = {
  //   method: 'GET',
  //   url: configData.msgStats.hitCountByDay,
  // };
  // return apiClient(options);
};

export const getOmwClickCounts = (daysOfHistory) => {
  throw new Error('not implemented');
  // const params = new URLSearchParams();
  // params.append('daysOfHistory', daysOfHistory);

  // const options = {
  //   method: 'GET',
  //   url: configData.msgStats.hitCount,
  //   params,
  // };
  // return apiClient(options);
};

export const getMonthlyBreakdown = (daysOfHistory) => {
  throw new Error('not implemented');
  // const params = new URLSearchParams();
  // params.append('daysOfHistory', daysOfHistory);

  // const options = {
  //   method: 'GET',
  //   url: configData.msgStats.monthlyBreakdown,
  //   params,
  // };
  // return apiClient(options);
};
